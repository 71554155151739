.user-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 20rem;
    border: solid 0.05rem var(--primary-border);
    border-radius: var(--control-border-radius);


    .user-list-search {
        position: relative;
        margin-bottom: .5rem;

        .ctl-checkbox {
            position: absolute;
            left: .5rem;
            top: 50%;
            transform: translateY(-50%);
        }

        input {
            padding-left: 2.5rem;
        }
    }


    .user-list-container {
        flex-grow: 1;
        height: 27rem;
        overflow-y: auto;
        padding: .5rem;
    }

    .user-pfp,
    .loading {
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 50%;
    }

    .user-pfp-placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 50%;
        color: var(--inverse-primary-text);
        background-color: var(--inverse-primary-background);
        font-weight: 700;
    }

    .loading {
        flex-grow: unset;
    }

    .user-info {
        .name {
            font-size: 1.2rem;
        }

        .email {
            font-size: .9rem;
        }
    }

    &-row {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0 .5rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
    }
}