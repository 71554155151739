.landing-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    height: 100dvh;
    min-width: 50rem;
    overflow: auto;

    .icon-item {
        cursor: pointer;
    }

    .landing-header {
        display: flex;
        align-items: center;
        gap: 2rem;

        .landing-title {
            font-size: 4rem;
            font-weight: 500;
        }

        .landing-img {
            img {
                width: 6rem;
                height: 6rem;
                border-radius: 0.5rem;
            }

            &.no-img {
                width: 6rem;
                height: 6rem;
                border-radius: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3rem;

                color: var(--inverse-primary-text);
                background-color: var(--inverse-primary-background);
                font-weight: 700;
            }

        }
    }

    .bubble {
        border: 1px solid var(--primary-border);
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;

        // flex-grow: 1;
        .btn-more {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 0.5rem;
            padding: 1rem;

            * {
                cursor: pointer;
            }
        }
    }

    .landing-second {
        display: flex;
        margin: 2rem 0;

        .landing-apps {
            margin-right: 2rem;
            min-height: 21.25rem;
            height: fit-content;

            h3 {
                padding: 1rem;
            }
        }

        .app-list {
            flex-wrap: wrap;
            display: flex;
            gap: 1rem;
            padding: 0 1rem;
            min-width: calc(5 * 7rem + 1rem);
            max-height: calc(2 * 7rem + 4rem);
            /* Force contains it to two lines. */
            overflow: hidden;
            transition: width 100ms ease;
        }

        .landing-notifications {
            flex-grow: 1;
            min-height: 21.25rem;

            .landing-notifications-header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid var(--primary-border);
                padding: 1rem;

                .notifications-search-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    height: 1.5rem;
                }
            }

        }
    }

    .landing-reports {
        display: flex;

        .landing-reports-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem;

            .carousel-arrows {
                gap: 2rem;
                display: flex;
            }
        }

        .reports-list {
            display: flex;
            gap: 1rem;
            overflow-x: hidden;
            padding: 0 1rem;
            scroll-behavior: smooth;
        }
    }
}