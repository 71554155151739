.report-card {
    display: flex;
    flex-direction: column;
    background-color: black;
    max-width: 16rem;
    cursor: pointer;
    max-height: 15rem;
    border-radius: 0.5rem;
    .img-container {
        display: flex;
        height: 60%;
        align-items: center;
        justify-content: center;
        img {
            height: 100%;
            object-fit: contain;
        }
    }
    .report-text {
        background-color: var(--tertiary-background);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: fit-content;
        padding: 0.5rem 1rem;
        border-radius: 0 0 0.5rem 0.5rem;
        .report-header {
            padding-bottom: 0.5rem;
            p {
                font-size: 0.9rem;
            }
        }
    }
    .report-text p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}